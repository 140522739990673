import React from 'react';

import { GlobalMessage } from '@/components/GlobalMessage/GlobalMessage';
import { term } from '@/shared/utils/stringUtils';
import { getRootStore } from '@/stores/rootStore';
import { CDD } from '@/typedJS';
import { Link } from '@mui/material';
import { observer } from 'mobx-react';
import { ErrorBoundary } from 'react-error-boundary';
import './InventorySearchView.sass';
import { SearchBar } from './components/SearchBar/SearchBar';
import { InventorySearchTable } from './components/Table/InventorySearchTable';
import { FieldTypeValues } from './components/consts';
import EditInventoryEventDialog from './components/inventory_dialogs/EditInventoryEventDialog';
import EditInventorySampleDialog from './components/inventory_dialogs/EditInventorySampleDialog';
import { InventorySearchViewInitialLoadProps } from './components/types';

function InventoryErrorBoundary({
  resetInventoryPreferences,
}: {
  resetInventoryPreferences: () => Promise<void>;
}): JSX.Element {
  // since some errors can also go wrong by the time we get the store values, we have this fallback
  const reset = async () => {
    await resetInventoryPreferences();
    window.location.reload();
  };
  return (
    <div>
      Something Went Wrong.{' '}
      <Link data-testid="reset-preferences" onClick={reset}>
        Resetting your search preferences might help.
      </Link>
    </div>
  );
}

@observer
class InventorySearchViewInternal extends React.Component<InventorySearchViewInitialLoadProps> {
  constructor(props: InventorySearchViewInitialLoadProps) {
    super(props);
    this.store.setupInventoryURLs(props);
    if (!this.store.setup) {
      this.setupPage();
    }
    // To update shown entries whenever available vaults get changed
    // TODO should probably more broadly refactor away from this kinda jquery pattern
    CDD.DataSourceSelection.updateCallback = this.store.submitNewQuery;
  }

  private async setupPage() {
    const props = await this.store.getInitialProps();

    const inventory_field_definitions_entries = FieldTypeValues.map(
      (fieldType) => [
        fieldType,
        [
          ...props.custom_field_definitions[fieldType],
          ...props.default_field_definitions[fieldType],
        ],
      ],
    );
    this.store.setupInventorySearchPage({
      ...props,
      inventory_field_definitions: Object.fromEntries(
        inventory_field_definitions_entries,
      ),
    });
  }

  get store() {
    return getRootStore().inventoryStore;
  }

  get links() {
    const { links } = this.props;
    return {
      entriesExportProgressUrl: links.inventory_entries_export_progress_url,
      entriesExportUrl: links.inventory_entries_export_url,
    };
  }

  get searchBarHandlers() {
    const {
      removeQueryFilter,
      updateQueryFilter,
      updateQueryMRV,
      addQueryFilter,
      updateQueryText,
      resetQueryFilters,
      submitNewQuery,
    } = this.store;
    return {
      updateQueryMRV,
      removeQueryFilter,
      updateQueryFilter,
      addQueryFilter,
      updateQueryText,
      submitQuery: submitNewQuery,
      resetQueryFilters,
    };
  }

  get sortProps() {
    const { sortedColumn, setSortBy } = this.store;
    return { sortBy: sortedColumn, setSortBy };
  }

  render(): React.ReactNode {
    const {
      loading,
      inventoryEntries,
      searchQuery,
      visibleColumns,
      availableColumns,
      currentlyEditingInventoryAsSample,
      currentlyEditingInventoryAsEvent,
      currentlyEditingInventoryBatches,
      totalCount,
      hasErrors,
      availableFilters,
      vault_name_map: vaultNameMap,
      user_structure_masked,
      all_units: allUnits,
      custom_field_definitions: { Event: eventFDs, Sample: sampleFDs },
    } = this.store;
    if (hasErrors) {
      throw new Error('Trigger Boundary');
    }
    return (
      <>
        <div id="inventorySearchView">
          <SearchBar
            sharedProps={{
              searchQuery,
              terminology: {
                entryTerminologySingle: term('sample'),
                entryTerminologyPlural: term('sample.other'),
              },
            }}
            handlers={this.searchBarHandlers}
            exportLinksProps={{
              onClickExport: this.store.submitExport,
              exportsAllowed: this.store.inventory_entries_export_url !== null,
            }}
            searchFilters={{
              availableFilters,
              vaultNameMap,
              user_structure_masked,
            }}
            entriesCount={{
              showingCount: inventoryEntries.length,
              totalCount,
            }}
          />
          {this.store.setup && !this.store.hasErrors && (
            <>
              <InventorySearchTable
                {...this.sortProps}
                fieldDefinitions={this.store.inventoryFieldDefinitions}
                hasMore={inventoryEntries.length < totalCount && !hasErrors}
                getNextPage={this.store.getNextPage}
                onChangeVisibleColumns={this.store.setVisibleColumnDetails}
                onClickEntry={this.store.handleEditInventory}
                onToggleSampleDepleted={this.store.handleToggleDeplete}
                availableColumns={availableColumns}
                visibleColumns={visibleColumns}
                entryRows={inventoryEntries}
                loading={loading}
                highlights={this.store.searchHighlights}
                presorted
                locationColumnId={
                  eventFDs.find((val) => val.name === 'Location').id as number
                }
              />
              <EditInventorySampleDialog
                isOpen={
                  currentlyEditingInventoryAsSample !== null &&
                  currentlyEditingInventoryAsEvent === null
                }
                value={currentlyEditingInventoryAsSample}
                batches={currentlyEditingInventoryBatches}
                fieldDefinitions={sampleFDs}
                eventFieldDefinitions={eventFDs}
                units={allUnits}
                searchDialog
                onSubmit={this.store.handleSubmitEditSample}
                onClose={this.store.handleCancelEditInventory}
                onDelete={this.store.handleDeleteSample}
              />
              <EditInventoryEventDialog
                isOpen={currentlyEditingInventoryAsEvent !== null}
                value={currentlyEditingInventoryAsEvent}
                fieldDefinitions={eventFDs}
                unit={currentlyEditingInventoryAsSample?.units ?? null}
                sample={currentlyEditingInventoryAsSample}
                onSubmit={this.store.handleSubmitEditEvent}
                onClose={this.store.handleCancelEditInventory}
                onDelete={this.store.handleDeleteEvent}
              />
            </>
          )}
        </div>
      </>
    );
  }
}
@observer
export class InventorySearchView extends React.Component<InventorySearchViewInitialLoadProps> {
  get store() {
    return getRootStore().inventoryStore;
  }

  render() {
    return (
      <>
        {this.store.hasErrors && <GlobalMessage messages={this.store.errors} />}
        <ErrorBoundary
          fallback={
            <InventoryErrorBoundary
              resetInventoryPreferences={this.store.resetInventoryPreferences}
            />
          }
        >
          <InventorySearchViewInternal {...this.props} />
        </ErrorBoundary>
      </>
    );
  }
}
