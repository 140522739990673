import { FilterTextValue } from './FilterTextValue';

// TODO dedupe with eln entries stuff where possible
export class FilterNumberValue extends FilterTextValue {
  inputProps = {
    type: 'number',
    inputProps: {
      step: 'any',
    },
  };
}
